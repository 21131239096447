import { FieldValues, Path, useController } from 'react-hook-form';
import { ZodForm } from '../forms/zod-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useId, useMemo } from 'react';
import { getSelectorFn, KeysOfTypeOrNull, Selection } from '@juulsgaard/ts-tools';

import { useTranslations } from '../../context/init-data.context';

interface Props<TOption = string> {
  label?: string,
  options: TOption[];
  getKey?: Selection<TOption, string | undefined>;
  getOption?: Selection<TOption, string | undefined>;
  placeholder?: string,
  helpText?: string
  clearable?: boolean;
}

interface FormProps<T extends FieldValues, TOption> extends Props<TOption> {
  form: ZodForm<T>,
  name: KeysOfTypeOrNull<T, string> & Path<T>,
}

export function FormSelectInput<T extends FieldValues, TOption>({
  form,
  name,
  label,
  placeholder,
  helpText,
  options,
  getKey,
  getOption,
  clearable = true
}: FormProps<T, TOption>) {

  const id = useId();

  const fieldInfo = form.getFieldInfo(name);
  const { field, fieldState } = useController({ control: form.control, name });

  const { validationLabels: { optional } } = useTranslations();

  const labelEl = !label ? undefined :
    fieldInfo.required ? label : <>{label} <span style={{ marginLeft: '3px' }}>({optional})</span></>;

  const mappedOptions = useMemo(() => {
    const keyFn: (o: TOption) => string | undefined = getKey ? getSelectorFn(getKey) : x => x?.toString() ?? '';
    const optionFn: (o: TOption) => string | undefined = getOption
      ? getSelectorFn(getOption)
      : x => x?.toString() ?? '';

    return options.map(x => (
      { key: keyFn(x) ?? '', option: optionFn(x) ?? '' }
    ));
  }, [options, getKey, getOption]);

  return (
    <div className="app-input app-select-input">
      <FormControl fullWidth margin="normal" error={fieldState.invalid}>

        {labelEl && <InputLabel shrink id={`${id}-label`}>{labelEl}</InputLabel>}

        <Select id={id} labelId={labelEl ? `${id}-label` : undefined} label={labelEl} displayEmpty
                MenuProps={{ disableScrollLock: true }}
                {...field} value={field.value ?? ''}>

          {clearable && <MenuItem value="">{placeholder}</MenuItem>}
          {mappedOptions.map(x => <MenuItem value={x.key} key={x.key}>{x.option}</MenuItem>)}

        </Select>

        {fieldState.invalid ?
          <FormHelperText error>{fieldState.error?.message}</FormHelperText> :
          helpText && <FormHelperText>{helpText}</FormHelperText>
        }

      </FormControl>
    </div>
  );
}

interface StandaloneProps<TOption> extends Props<TOption> {
  value: string | undefined;
  onValueChange?: (value: string | undefined) => void;
  required?: boolean;
}

export function SelectInput<TOptions>({
  value,
  onValueChange,
  required,
  label,
  placeholder,
  helpText,
  options,
  getKey,
  getOption,
  clearable
}: StandaloneProps<TOptions>) {

  const id = useId();

  const { validationLabels: { optional } } = useTranslations();

  const labelEl = !label ? undefined :
    required ? label : <>{label} <span style={{ marginLeft: '3px' }}>({optional})</span></>;

  const mappedOptions = useMemo(() => {
    const keyFn: (o: TOptions) => string | undefined = getKey ? getSelectorFn(getKey) : x => x?.toString() ?? '';
    const optionFn: (o: TOptions) => string | undefined = getOption
      ? getSelectorFn(getOption)
      : x => x?.toString() ?? '';

    return options.map(x => (
      { key: keyFn(x) ?? '', option: optionFn(x) ?? '' }
    ));
  }, [options, getKey, getOption]);

  return (
    <div className="app-input app-select-input">
      <FormControl fullWidth margin="normal">

        {labelEl && <InputLabel shrink id={`${id}-label`}>{labelEl}</InputLabel>}

        <Select id={id} labelId={labelEl ? `${id}-label` : undefined} label={labelEl} displayEmpty
                MenuProps={{ disableScrollLock: true }}
                value={value ?? ''} onChange={x => onValueChange?.(x.target.value || undefined)}>

          {clearable && <MenuItem value="">{placeholder}</MenuItem>}
          {mappedOptions.map(x => <MenuItem value={x.key} key={x.key}>{x.option}</MenuItem>)}

        </Select>

        {helpText && <FormHelperText>{helpText}</FormHelperText>}

      </FormControl>
    </div>
  );
}