import { styled } from '@stitches/react';
import IconBox from 'DesignComponents/Molecules/IconBox/IconBox';
import { SrOnlyStyle } from 'DesignSystem/Accessibility/Utils';
import { useVariantData } from 'context/variant.context';

function SubscriptioOfferPriceExplaination() {
  const {
    state: { selectedVariant },
  } = useVariantData();

  if (!selectedVariant) {
    return <></>;
  }

  const priceExplanation =
    selectedVariant.subscriptionOfferDetails.priceExplanation;

  return (
    <IconBox
      icon="Price"
      title={'<h4>' + priceExplanation.priceCalculationHeader + '</h4>'}
      opened
    >
      <Table>
        <caption>{priceExplanation.priceCalculationHeader}</caption>
        <tbody>
          <tr>
            <th scope="row">{priceExplanation.offerIssuesLabel}</th>
            <td>
              {priceExplanation.offerIssuesPrice.priceAsString}{' '}
              <Currency>
                {priceExplanation.offerIssuesPrice.currencySymbol}
              </Currency>
            </td>
          </tr>
          {priceExplanation.premiumLabel && (
            <tr>
              <th scope="row">{priceExplanation.premiumLabel}</th>
              <td>
                {priceExplanation.premiumPrice.priceAsString}{' '}
                <Currency>
                  {priceExplanation.offerIssuesPrice.currencySymbol}
                </Currency>
              </td>
            </tr>
          )}
          <tr>
            <th scope="row">{priceExplanation.totalAmountLabel}</th>
            <td>
              {priceExplanation.totalAmount.priceAsString}{' '}
              <Currency>
                {priceExplanation.offerIssuesPrice.currencySymbol}
              </Currency>
            </td>
          </tr>
          {priceExplanation.discountAmount && (
            <DiscountRow>
              <th scope="row">{priceExplanation.discountLabel}</th>
              <td>
                -{priceExplanation.discountAmount.priceAsString}{' '}
                <Currency>
                  {priceExplanation.offerIssuesPrice.currencySymbol}
                </Currency>
              </td>
            </DiscountRow>
          )}
          <tr>
            <th scope="row">
              <WeightSemiBold>{priceExplanation.priceLabel}</WeightSemiBold>
            </th>
            <td>
              <WeightSemiBold>
                {priceExplanation.price.priceAsString}{' '}
                <Currency>
                  {priceExplanation.offerIssuesPrice.currencySymbol}
                </Currency>
              </WeightSemiBold>
            </td>
          </tr>
        </tbody>
      </Table>
    </IconBox>
  );
}

const DiscountRow = styled('tr', {
  color: '$semanticError',
});

const Currency = styled('span', {
  fontSize: '$fontSize100',
});

const WeightSemiBold = styled('span', {
  fontFamily: 'fontSemiBold',
  color: '$onSurface',
});

const Table = styled('table', {
  width: '100%',
  '& th, td': {
    paddingBottom: '12px',
    textAlign: 'left',
    fontWeight: '$fontWeightRegular',
  },
  '& tr:last-child :where(td, th)': {
    paddingTop: '20px',
  },
  '& tr td:last-child': {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  ' & caption': {
    ...SrOnlyStyle,
  },
});

export default SubscriptioOfferPriceExplaination;
