import { styled } from 'Theme/stitches.config';
import { ProductUSP } from './ProductUSP';
import ProductCTA from './ProductCTA';

import { useVariantData } from 'context/variant.context';
import { Variant } from 'Enums/Variant.enum';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';

import Heading from 'DesignSystem/Typography/Headings/Heading';
import IconBox from 'DesignComponents/Molecules/IconBox/IconBox';
import SubscriptionOfferProductBasePageModel
  from 'Models/Pages/ProductPage/SubscriptionOffer/SubscriptionOfferProductBasePageModel.interface';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import SubscriptioOfferPriceExplaination from './SubscriptionOfferPriceExplaination';
import styles from './SubscriptionAttributeArea.module.scss';
import { SelectInput } from '../../../lib/inputs/FormSelectInput';

import { useTranslations } from '../../../context/init-data.context';

type PropTypes = {
  productPage: SubscriptionOfferProductBasePageModel;
  displayProductCTA: boolean;
  openFirstAccordionItem?: () => void;
};

function SubscriptionAttributeArea({
  productPage,
  displayProductCTA = true,
  openFirstAccordionItem
}: PropTypes) {

  const { variationCollection } = productPage;

  const variantOptions = useMemo(
    () => variationCollection.filter((variant) => variant.subscriptionOfferDetails.showInSelectList),
    [variationCollection]
  );

  const {
    productLabels: { shortDescriptionReadMoreText, productUsps },
    subscriptionOfferLabels: { includeGimmickCheckboxText }
  } = useTranslations();

  const { variantDispatch } = useVariantData();
  const [gimmickSelected, setGimmickSelected] = useState(false);

  const [selection, setSelection] = useState<string | undefined>(
    () => variationCollection.find(variant => !variant.subscriptionOfferDetails.hasGimmick)?.code
      ?? variationCollection[0].code
  );

  const variant = useMemo(
    () => selection ? variationCollection.find(variant => variant.code == selection) : undefined,
    [selection, variationCollection]
  );

  const variantWithGimmick = useMemo(
    () => variationCollection.find((variant) =>
      variant.subscriptionOfferDetails.twinVariant == selection &&
      variant.subscriptionOfferDetails.hasGimmick
    ),
    [variationCollection, selection]
  );

  const activeVariant = gimmickSelected ? variantWithGimmick ?? variant : variant;

  useEffect(() => {
    if (!activeVariant) return;
    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: activeVariant,
      hasSizes: false
    });
  }, [activeVariant]);

  function handleGimmickCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    setGimmickSelected(e.target.checked);
  }

  const gimmickPrice = variant && variantWithGimmick ? variantWithGimmick.nowPrice.price - variant.nowPrice.price : 0;

  return (
    <AttributeContainer>
      <AttributeWrapper>
        <Heading size={'l'} tag="h1" color="Regular" css={{ mb: '$s100' }}>
          {productPage.displayName}
          {productPage.nameOfAdditionalItems && (
            <SubHeading>{'+ ' + productPage.nameOfAdditionalItems}</SubHeading>
          )}
        </Heading>
        {productPage.shortDescription && (
          <BodyText size="l">
            {productPage.shortDescription}
            {productPage.hasLongDescription && (
              <>
                {' '}
                <ReadMore
                  onClick={(e) => {
                    e.preventDefault();
                    if (openFirstAccordionItem) {
                      openFirstAccordionItem();
                    }
                  }}
                >
                  {shortDescriptionReadMoreText}
                </ReadMore>
              </>
            )}
          </BodyText>
        )}

        {
          variantOptions.length > 1 &&
          <SelectInput value={selection} options={variantOptions} getKey={x => x.code}
                       getOption={x => x.nameOfAdditionalItems} onValueChange={setSelection} />
        }

        {
          variantWithGimmick &&
          <Checkbox
            button
            isChecked={gimmickSelected ?? false}
            onCheck={(e) => handleGimmickCheckboxChange(e)}
          >
            {() => <div className={styles.gimmickContent}>
              <p className={styles.text}>{includeGimmickCheckboxText}</p>
              <p className={styles.price}>+ {gimmickPrice} {variant?.nowPrice.currencySymbol}</p>
            </div>}
          </Checkbox>
        }

        {displayProductCTA && <ProductCTA />}

        <ProductBoxes>
          {activeVariant?.freightAdditionalInfo && (
            <IconBox
              icon="Shipping"
              title={activeVariant.freightAdditionalInfo}
            ></IconBox>
          )}
          {activeVariant && (
            <IconBox
              icon="Subscription"
              title={activeVariant.additionalInfo}
            ></IconBox>
          )}

          <SubscriptioOfferPriceExplaination />

          <ProductUSP
            productUsp={productPage.usp}
            globalUsp={productPage.globalUsp}
            label={productUsps}
          />
        </ProductBoxes>
      </AttributeWrapper>
    </AttributeContainer>
  );
}

const SubHeading = styled('div', {
  fontSize: '$fontSize500',
  fontFamily: 'fontSemiBold',
  color: '$onSurface'
});

const AttributeContainer = styled('div', {});

const AttributeWrapper = styled('div', {
  w: '100%'
});

const ReadMore = styled('a', {
  color: '$emphasisedLinkColor',
  fontFamily: 'fontSemiBold',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    cursor: 'pointer'
  }
});

const ProductBoxes = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
});

export default SubscriptionAttributeArea;
